<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card" shadow="never">
                <!-- 按钮 -->
                <!-- <el-row style="height:40px;">
                    <el-button type="primary" @click="currentId=0;createShareFlag=true" class="buttons">新增商品</el-button>
                </el-row> -->
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="仓库" >
                        <el-select v-model="req.warehouse_id" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in wareHouseList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            placeholder="请输入关键词搜索"
                            prefix-icon="el-icon-search"
                            v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>

                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width:95%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="创建时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.create_time }}</template>
                    </el-table-column>
                    <el-table-column label="商品编码"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.specification_code}}</template>
                    </el-table-column>

                    <el-table-column label="商品名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info?scope.row.goods_info.name:'' }}</template>
                    </el-table-column>
                    <el-table-column label="所属分类"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info.classify_info.name}}</template>
                    </el-table-column>

                    <el-table-column label="属性规格"  min-width="65" align="center">
                        <template slot-scope="scope">
                            <span v-for="(item,key) in scope.row.dispose_info" :key="key">
                                {{item}}
                                <span v-if="key!=scope.row.dispose_info.length-1">,</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="图片"  min-width="65" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.goods_info.pic.length>0" >
                                <img :src="baseUrl+'/'+scope.row.goods_info.pic[0].pic" 
                                @click="showPic(baseUrl+'/'+scope.row.goods_info.pic[0].pic)" alt="" 
                                style="width:40px;height:40px;cursor:pointer;">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="实物库存"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.inventory }}</template>
                    </el-table-column>
                    <el-table-column label="占用库存"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.occupy }}</template>
                    </el-table-column>
                    <el-table-column label="可用库存"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.available }}</template>
                    </el-table-column>
                    <el-table-column label="库存成本（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.cost }}</template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="fenbu(scope.row.id)" >
                                库存分布
                            </el-button>
                            <el-button type="text" size="small"  @click="log(scope.row)">
                                库存流水
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>

        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加商品"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        :close-on-click-modal="false"
        width="80%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="库存分布"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList 
            :id="currentId" 
            :warehouseId="req.warehouse_id" 
            ></shareList>
        </el-dialog>

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="showPicFlag"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="showSrc" alt="">
        </div>
        </el-dialog>


    </div>
</template>

<script>
import createShare from './creatDialog';
import shareList from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            baseUrl: tools.baseURL(),
            //上传图片列表地址
            uploadImages: [

            ],
            createShareFlag:false,
            shareListFlag:false,
            showPicFlag:false,
            showSrc:'',
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            wareHouseList:[],
            req:{
                limit:30,
                page:1,
                keywords:'',
                warehouseId:''
            }
        }
    },
    components:{
        createShare,shareList
    },
    methods:{
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            apis.stockList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.tableData.forEach(item=>{
                    item.goods_info.pic = JSON.parse(item.goods_info.pic);
                    item.cost = tools.setPrice(item.cost);
                });
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        showPic(src){
            this.showPicFlag = true;
            this.showSrc = src;
        },
        remove(id, key) {
            this.$confirm('确定删除此记录?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.goodsDelete({ id: id}).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        },
        log(item){
            this.currentId = item.id;
            this.$router.push({
                path:'/stock/log',
                query:{
                    id:this.currentId,
                    warehouseId:this.req.warehouse_id
                }
            })
        },
        fenbu(id){
            this.shareListFlag = true;
            this.currentId = id;
        }
    },
    created(){
        this.getList();
        //初始化数据源
        apis.purchaseSource().then(res=>{
            if(tools.msg(res,this)){
                this.wareHouseList = res.data.warehouse;
            }
        }).catch(err=>{
            tools.err(err,this);
        });

    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>