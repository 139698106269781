import axios from 'axios'; // 引入axios
import tools from '../../../extends/tools.js'; // 引入axios
import JSONBIG from 'json-bigint'

// vant的toast提示框组件，大家可根据自己的ui组件更改。
// 环境的切换

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://a.dpzog.com:30001';
} else if (process.env.NODE_ENV == 'alpha') {
    axios.defaults.baseURL = 'http://a.dpzog.com:30002';
} else if (process.env.NODE_ENV == 'production') {
    if (location.protocol == 'https:') {
        axios.defaults.baseURL = 'https://a.dpzog.com';
    } else {
        axios.defaults.baseURL = 'http://a.dpzog.com:30002';
    }
}

//axios在这里默认把响应体从json字符串转成了js对象
axios.defaults.transformResponse = [function(data) {
    try {
        //作用1：把json字符串转为js对象
        //作用2：把里面的大数字做安全处理
        if (typeof data == 'string') {
            return JSONBIG.parse(data);
        } else {
            return data;
        }
    } catch (err) {
        this.$alert('发生网络错误，请尝试刷新页面再试，如无效请联系客服！' + JSON.stringify(err));
        console.log(err);
        return data;
    }
}]

// 响应拦截器
axios.interceptors.response.use(
    // response => {
    //     // 统一定义状态码为200时，表示请求成功，其他请求失败
    //     if (response.data.code === 200 || response.data.code === 299) {
    //         return Promise.resolve(response);
    //     } else {
    //         return Promise.reject(response);
    //     }
    // }
    // // 可根据错误响应码判断状态，做出相应的处理
    // error => {
    //     if (error.data.code) {
    //         switch (error.data.code) {
    //             // 401: 未登录
    //             // 未登录时跳转登录界面，登录成功后回调登录前被拦截的地址 query.redirect
    //             case 10041:
    //                 router.replace({
    //                     path: '/home'
    //                 });
    //                 alert('您的登录已超时，请重新登录！');
    //                 break;
    //             case 10042:
    //                 alert('登录状态异常，详情请咨询客服');
    //                 router.replace({
    //                     path: '/home'
    //                 });
    //                 break;
    //             case 10043:
    //                 alert('无权限访问此页面');
    //                 break;
    //             default:
    //                 alert('发生未知错误，详情请咨询客服');
    //         }
    //         return Promise.reject(error.response);
    //     }
    // }
);

localStorage.setItem('baseUrl', axios.defaults.baseURL);
// let authorization = localStorage.getItem('authorization-bearer') ? localStorage.getItem('authorization-bearer') : 'none';
// axios.defaults.baseURL='http://erp.zhichengshangxin.com/api/'
axios.defaults.timeout = 30000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

//get请求
export function get(url, params) {
    if (!params) {
        params = {};
    }
    let headers = tools.getToken();
    //2021.05.26处理孔明锁，增加参数
    // params.currentPage____ = location.href;
    // params._ati = Math.random();
    delete params.headers;
    return new Promise((resolve, reject) => {
        return axios.request({
                url: url,
                method: 'GET',
                params: params,
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            });
    });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
//put请求
export function getFile(url, params) {
    if (!params) {
        params = {};
    }
    let headers = tools.getToken();
    //2021.05.26处理孔明锁，增加参数
    return new Promise((resolve, reject) => {
        return axios.request({
                url: url,
                method: 'GET',
                params: params,
                responseType: "blob",
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            });
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    if (!params) {
        params = {};
    }
    //2021.05.26处理孔明锁，增加参数
    // params.currentPage____ = location.href;
    // params._ati = Math.random();
    let headers = tools.getToken();
    delete params.headers;
    return new Promise((resolve, reject) => {
        return axios.request({
                url: url,
                method: 'POST',
                data: params,
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            });
    });
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
//put请求
export function put(url, params) {
    if (!params) {
        params = {};
    }
    //2021.05.26处理孔明锁，增加参数
    // params.currentPage____ = location.href;
    // params._ati = Math.random();
    let headers = tools.getToken();
    delete params.headers;
    return new Promise((resolve, reject) => {
        return axios.request({
                url: url,
                method: 'PUT',
                params: params,
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            });
    });
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
//put请求
export function del(url, params) {
    if (!params) {
        params = {};
    }
    //2021.05.26处理孔明锁，增加参数
    // params.currentPage____ = location.href;
    // params._ati = Math.random();

    let headers = tools.getToken();
    delete params.headers;
    return new Promise((resolve, reject) => {
        return axios.request({
                url: url,
                method: 'DELETE',
                params: params,
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            });
    });
}