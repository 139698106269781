import tools from "../../../extends/tools";
import E from 'wangeditor';
import apis from "../../common/js/api2";
import { Base64 } from 'js-base64';

export default {
    //初始化
    init() {
        //记录初始化数据
        this.saveDataInit = tools.copy(this.saveData);
        //
        const timer = setInterval(() => {
            if (!document.getElementById('goods_detail')) {
                return;
            }
            clearInterval(timer);
            const editor = new E('#goods_detail');
            editor.config.uploadImgServer = tools.baseEditorUpload();
            editor.config.uploadImgHeaders = tools.getToken();

            editor.create();
            this.editor = editor;
            //等编辑器加载完毕后再显示数据，避免出错
            if (this.id > 0) {
                //编辑获取商品信息
                apis.goodsDetail({ id: this.id }).then(res => {
                    if (tools.msg(res, this)) {
                        let postData = JSON.parse(res.data.postdata);
                        //初始化列表数据，主要是规格属性组装
                        this.disposeInfoArr = postData.disposeInfoArr;
                        this.dispose = postData.selectDespose;
                        this.disposePerm = postData.disposePerm;
                        //初始化简单数据
                        this.editor.txt.html(Base64.decode(res.data.details));
                        this.saveDataTmp.pic = res.data.pic;

                        //普通数据
                        for (let i in res.data) {
                            for (let o in this.saveData) {
                                if (i === o) {
                                    this.saveData[o] = res.data[i];
                                }
                            }
                        }

                        //特殊数据
                        this.saveData.label_id = [];
                        let arr = res.data.label_id.split(',');
                        for (let i = 0; i < arr.length; i++) {
                            this.saveData.label_id.push(parseInt(arr[i]));
                        }
                    }
                }).catch(err => {
                    tools.err(err, this);
                });
            }

        }, 10);
        //获取各种数据源
        apis.goodsSource().then(res => {
            if (tools.msg(res, this)) {
                this.source = res.data;
            }
        }).catch(err => {
            tools.err(err, this);
        });
    },
    // 
    getAttrName(item, i) {
        let attr_value = '';
        i.dispose.forEach((ii, kk) => {
            item.dispose_info.forEach((iii, kkk) => {
                if (i.dispose[kk] == iii.id) {
                    attr_value = iii.value;
                }
            });
        });
        return attr_value;
    },
    changePic(file) {
        this.currentDespose.pic = file.font;
    },
    changeItem(i) {
        this.currentDespose = i;
    },
    // 
    desposeChange() {
        this.disposeInfoArr = [];
        const spliter = '--_--';
        this.dispose.forEach(item => {
            this.source.attribute.forEach(i => {
                if (item === i.id) {
                    this.disposeInfoArr.push(i);
                }
            });
        });
        //获取所有规格
        let arr = [];
        this.disposeInfoArr.forEach((item, key) => {
            if (item.enable_status !== '开启') {
                return;
            }
            let a = [];
            item.dispose_info.forEach((i, k) => {
                a.push(`${i.id}`);
            })
            arr.push(a);
        });
        //获取规格的全排列
        let pArr = tools.combine(arr);
        //再把相关的数据还原成与接口一致的数据
        this.disposePerm = [];
        pArr.forEach((item, key) => {
            let obj = {
                "specification_code": '', //规格编码
                "specification_bar_code": '', //规格条形码
                "retail_price": '0.00', //零售价  分  
                "max_stock": '0', //最高库存预警值
                "min_stock": '0', //最低库存预警值
                "pic": "" //规格图片
            };
            let dispose = [];
            item.forEach((i, k) => {
                let p = i.split(spliter);
                p.forEach(ii => {
                    dispose.push(ii);
                });
            });
            obj.dispose = dispose;
            this.disposePerm.push(obj);
        });
    },
    showPicModal(item, b) {
        this.$set(item, 'showModal', b);
    },
    showPreview(item) {
        this.dialogImageUrl = this.baseUrl + '/' + item.pic;
        this.dialogVisible = true;
    },
    deleteImg(key) {
        this.saveDataTmp.pic.splice(key, 1);
    },
    beforeUpload() {
        this.uploadLoading = true;
    },
    uploadSuccess(file) {
        if (file.code != 200) {
            this.$message({
                type: 'error',
                message: '上传失败'
            })
            return;
        }

        this.saveDataTmp.pic.push({
            pic: file.font
        });
    },
    //插入图片
    pickPicture(file) {
        this.uploadLoading = false;
        this.saveData.portrait = file.font;
        this.imageUrl = this.baseUrl + file.font;
    },
    save() {
        // 详情
        this.saveData.details = Base64.encode(this.editor.txt.html());
        // return;
        //保存一些额外数据,用于编辑时展示
        this.saveData.dispose = this.dispose;
        this.saveData.pic = this.saveDataTmp.pic;

        this.saveData.postdata = JSON.stringify({
            //被选中的属性值
            selectDespose: this.dispose,
            //转换后的SKU
            disposePerm: this.disposePerm,
            //被选中的sku详情
            disposeInfoArr: this.disposeInfoArr,
            saveData: this.saveData
        });
        //正式转换的数据
        this.saveData.dispose = JSON.stringify(this.disposePerm);
        this.saveData.pic = JSON.stringify(this.saveDataTmp.pic);
        //判断是修改还是新增
        if (this.id > 0) {
            this.saveData.id = this.id;
            //保存
            apis.goodsUpdate(this.saveData).then((res) => {
                if (res.code != 200) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return;
                }
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err) => {
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });
            return;
        }
        //新增
        apis.addGoods(this.saveData).then((res) => {
            if (res.code != 200) {
                this.$message({
                    type: 'error',
                    message: res.msg
                });
                return;
            }
            //显示分享码
            this.saveComplete = true;
            this.$message({
                type: 'success',
                message: '添加成功'
            });
            //通知列表页更新数据
            this.fleshList();
        }).catch((err) => {
            console.log(err);
            this.$message('网络不通，请稍候重试-');
        });
    },
    exit() {
        this.$emit('shareExit')
    },
    fleshList() {
        this.$emit('fleshList')
    }
}