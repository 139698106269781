<template>
  <div>
       <!-- 创建 -->
    <el-table
            ref="multipleTable"
                        :height="__tableHeight__"
            :data="tableData"
            stripe
            tooltip-effect="dark"
            style="width: 96%;text-align: center">
        <!-- <el-table-column align="center" type="selection" width="65 "> </el-table-column> -->

        <el-table-column label="门店"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.warehouse_info.shop_info?scope.row.warehouse_info.shop_info.name:'' }}</template>
        </el-table-column>

        <el-table-column label="仓库"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.warehouse_info?scope.row.warehouse_info.name:'' }}</template>
        </el-table-column>

        <el-table-column label="实物库存"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.inventory }}</template>
        </el-table-column>

        <el-table-column label="占用库存"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.occupy }}</template>
        </el-table-column>
        <el-table-column label="可用库存"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.available }}</template>
        </el-table-column>
        <el-table-column label="库存成本（元）"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.cost }}</template>
        </el-table-column>

    </el-table>
    <!-- 页码 -->
    <div class="block" >
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
        </el-pagination>
    </div>
    <div style="height:60px;"></div>
  </div>
</template>

<script>
import {shareList,shareDel } from "../../common/js/api";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        },
        warehouseId:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            tableData:[],
            total:0,
            currentPage:1,
            req:{
                limit:30,
                page:1
            }
        }
    },
    methods:{
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            this.req.id = this.id;
            this.req.warehouse_id = this.warehouseId;
            apis.stockDistribution(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
                this.tableData.forEach(item=>{
                    item.cost = tools.setPrice(item.cost);
                });
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                shareDel({ id: id }).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        update(tplId,){
            
        }
    },
    created(){
        this.getList();
    }
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>