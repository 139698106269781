<template>
    <div class="flex_box" style="">
        <top></top>
        <nav-bar></nav-bar>
        <list-nei></list-nei>
    </div>
</template>

<script>
    import NavBar from "../../components/common/NavBar";
    import Top from "../../components/common/top";
    import listNei  from "./base/listNei";
    export default {
        data(){
            return {
                roleId:1
            }
        },
        methods:{
            
        },
        name: "printList",
        mounted(){
            
        },
        components:{
            listNei,
            NavBar,
            Top
        },
    }
</script>