<template>
  <div>
       <!-- 创建 -->
        <div style="width:100%;margin:0 auto;background:#fff;">
            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">基础信息</div>
                </div>
                <!-- 基础信息 -->
                <el-row>
                    <el-input placeholder="请输入内容" v-model="saveData.product_code" size="small" class="baseInput">
                        <template slot="prepend">商品编码</template>
                    </el-input>

                    <el-input placeholder="请输入内容" v-model="saveData.bar_code" size="small" class="baseInput">
                        <template slot="prepend">条形码</template>
                    </el-input>

                    <el-input placeholder="请输入内容" v-model="saveData.name" size="small" class="baseInput">
                        <template slot="prepend">商品名称</template>
                    </el-input>

                    <div style="display:inline-block;height:50px;line-height:50px;" class="baseInput">
                        <div class="label_">
                            商品分类
                        </div>
                        <el-cascader
                            style="display:inline-block;"
                            v-model="saveData.classify_id"
                            size="small"
                            :options="source.classify"
                            :show-all-levels="false"
                            :props="{ 
                                expandTrigger: 'hover',
                                children:'child',
                                emitPath:false,
                                value:'id',
                                label:'name'
                            }"></el-cascader>
                    </div>

                    <el-input placeholder="请输入内容" v-model="saveData.quality_guarantee_period" size="small" class="baseInput">
                        <template slot="prepend">保质期(月)</template>
                    </el-input>
                    <div style="display:inline-block;height:50px;line-height:50px;" class="baseInput">
                        <div class="label_">
                            上架时间
                        </div><el-date-picker
                            size="mini"
                            v-model="saveData.launch_date"
                            type="date"
                            placeholder="选择日期">
                            </el-date-picker>
                    </div>
                    <div style="display:inline-block;height:50px;line-height:50px;" class="baseInput">
                        <div class="label_">
                            商品标签
                        </div>
                        <el-select 
                            multiple
                            placeholder="商品标签" v-model="saveData.label_id" size="small" >
                            <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.label" :key="key"></el-option>
                        </el-select>
                    </div>
                    <div style="display:inline-block;height:50px;line-height:50px;" class="baseInput">
                        <div class="label_">
                            商品单位
                        </div>
                        <el-select placeholder="商品单位" v-model="saveData.unit_id" size="small" >
                            <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.unit" :key="key"></el-option>
                        </el-select>
                    </div>
                    <div style="display:inline-block;height:50px;line-height:50px;" class="baseInput">
                        <div class="label_" style="background:#fff;color:#000;border:none;">
                            商品状态
                        </div> <el-switch v-model="saveData.status"></el-switch>
                    </div>
                </el-row>
            </div>

            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">基础信息</div>
                </div>
                <el-row>
                   <div style="display:inline-block;height:50px;line-height:50px;" >
                        <div class="label_">
                            选择属性
                        </div>
                        <el-select 
                            multiple
                            @change="desposeChange"
                            placeholder="商品规格" v-model="dispose" size="small" >
                            <el-option 
                            :label="item.name"
                            :value="item.id"
                            v-for="(item,key) in source.attribute" :key="key"></el-option>
                        </el-select>
                    </div>
                </el-row>
                <!-- 规格信息 -->
                <div class="attr_row" v-for="(item,key) in  disposeInfoArr" :key="key" v-show="item.enable_status === '开启'">
                   <span>{{item.name}}</span> 
                   <div class="label2"
                   v-for="(i,k) in item.dispose_info"
                   :key="k">{{i.value}}</div>
                </div>
                
                <div class="attr_row" style="margin:0 0 30px 0;">
                    <table class="mytable" style="width:100%;">
                        <thead class="thead">
                            <tr class="rows ">
                                <td class="cols" v-for="(item,key) in  disposeInfoArr" :key="key">{{item.name}}</td>
                                <td class="cols">图片</td>
                                <td class="cols">规格编码</td>
                                <td class="cols">规格条形码</td>
                                <td class="cols">零售价（元）</td>
                                <td class="cols">最高库存预警</td>
                                <td class="cols">最低库存预警</td>
                            </tr>
                        </thead>
                        <tbody class="tbody" v-if="disposePerm.length>0">
                            <tr class="rows " v-for="(i,k) in disposePerm" :key="k">
                                <td class="cols" v-for="(item,key) in disposeInfoArr" :key="key">
                                    {{getAttrName(item,i)}}
                                </td>
                                <td class="cols">
                                    <div class="cols_upload " >
                                        <el-upload
                                        style="width:40px;height:40px;position:absolute;top:0px;left:0px;opacity:0;"
                                        :show-file-list="false"
                                        :action="fileUrl"
                                        :headers="authorizationBearer"
                                        :on-success="changePic">
                                        <el-button @click="changeItem(i)"
                                        size="small" type="primary" 
                                        style="width:40px;height:40px;position:absolute;top:0px;left:0px;opacity:0;"></el-button>
                                        </el-upload>
                                        <i class="el-icon-plus" v-if="i.pic==''"></i>
                                        <img v-else :src="baseUrl+'/'+i.pic" alt="">
                                    </div>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.specification_code"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.specification_bar_code"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.retail_price"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.max_stock"></el-input>
                                </td>
                                <td class="cols">
                                    <el-input class="cols_input" size="mini" v-model="i.min_stock"></el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">商品图片(第一张为主图)</div>
                </div>

                <div class="attr_row">
                    <div class="cols_upload big"  v-for="(item,key) in saveDataTmp.pic" :key="key">
                        <img :src="baseUrl+'/'+item.pic" alt="">
                        <div class="pic_modal"  >
                            <!-- 看大图 -->
                            <i class="el-icon-zoom-in" @click="showPreview(item)"></i> 
                            <!-- 删除 -->
                            <i class="el-icon-delete-solid" @click="deleteImg(key)"></i> 
                        </div>
                    </div>
                    <div class="cols_upload big">
                        <el-upload
                            :action="fileUrl"
                            multiple
                            :headers="authorizationBearer"
                            :show-file-list="false"
                            :on-success="uploadSuccess">
                            <i class="el-icon-plus" style="font-size:30px;"></i>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="add_container">
                <div  class="add_split">
                    <div class="leftBar"></div>
                    <div class="leftBarWord">商品详情</div>
                </div>
                <div class="attr_row">
                    <!-- 商品详情 -->
                    <div id="goods_detail">

                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button   @click="exit();">关 闭</el-button>
                <el-button type="primary"  @click="save();">保 存</el-button>
            </div>
        </div> 

        <el-dialog
        :modal="false"
        title="大图"
        :visible.sync="dialogVisible"
        width="600px">
        <div style="max-width:800px;max-height:500px;">
            <img style="width:100%;" :src="dialogImageUrl" alt="">
        </div>
        </el-dialog>

  </div>
</template>

<script>


import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import methods from "./create";
import data from "./createData";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    components:{
        
    },
    //退出时整理数据
    destroyed(){
        this.saveData = tools.copy(this.saveDataInit);
        this.dispose = [];
        this.disposeInfoArr = [];
        this.disposePerm = []; //生成的排列组合数据，全排列
        this.uploadImages = [];
    },
    data(){
        return data;
    },
    methods:methods,
    created(){
        this.init();
    }
}
</script>


<style scoped lang="less">
    @import url('../../../assets/less/normal');
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
  .add_container{
      min-height:100px;
  }


  .baseInput{
      max-width: 290px;margin:0 30px 15px 0;
  }
  .label_{
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border: 1px solid #DCDFE6;
    white-space: nowrap;
    height: 30px;
    line-height:31.8px;
    padding:0 5px 0 5px ;
    display: inline-block;
  }
.label2{
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border: 1px solid #DCDFE6;
    white-space: nowrap;
    height: 30px;
    line-height:31.8px;
    padding:0 20px 0 20px ;
    display: inline-block;
    margin-left:30px;
  }
.attr_row{
    margin:20px 0;
}
.cols_input{
    width:100px;
}
.cols_upload.big{
    width:148px;height:148px;line-height:148px;display: inline-block;overflow: hidden;margin-left:10px;
    .pic_modal{
        width:80px;height:30px;line-height: 30px;background:#000;opacity: 0.7;
        position:absolute;z-index: 8;top:60px;left:30px;
        i{
            font-size:18px;display: inline-block;color:#fff;
        }
        i.el-icon-delete-solid{
            margin-left:15px;
        }
    }
}
.cols_upload{
    position:relative;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
}
.cols_upload img{
    width:100%;
    height:100%;
}

</style>