import {get, post, del, put } from "./http"
import tools from '../../../extends/tools';

//登陆
export const login = p => post('/api/admin/login', p); // 普通登录
export const loginJump = p => post('/api/admin/authorization_login', p); // 跳转登录
export const setRole = p => post('/api/admin/reg/stepthree', p); // 完善信息
// 注册
export const reg = p => post('/api/admin/reg/stepone', p); // 手机号注册
export const industry = p => get('/api/admin/industry', p); // 获取行业列表
export const scale = p => get('/api/admin/scale', p); // 公司规模
export const steptwo = p => post('/api/admin/reg/steptwo', p); // 完善信息提交
// 打印模版处理
export const editorInfo = p => get('/api/print/editorInfo', p); // 模版初始化数据
export const fileUp = p => post('/api/admin/file_up', p); // 保存模版
export const smsCode = p => get('/api/admin/send_sms/' + p); //获取验证码
export const checkTplCode = (p) => {
    let code = p.code;
    delete p.code;
    return put('/api/print/expand/' + code, p);
}; //输入验证码

export const shareAdd = p => post('/api/print/share', p); // 添加
export const shareDetail = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return get('/api/print/share/' + id, p);
}; // 分享码详情
export const shareList = (p) => {
    let id = p.id;
    delete p.id;
    return get('/api/print/sharelist/' + id, p);
}; // 分享码列表
export const shareDel = (p) => {
    let id = p.id;
    delete p.id;
    return del('/api/print/share/' + id, p);
}; // 分享码列表
export const shareSave = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return put('/api/print/share/' + id, p);
}; // 分享码核销


/**
 * 员工管理
 */
//新增
export const staffAdd = (p) => {
    p.headers = tools.getToken();
    return post('/api/staff/handle', p);
};
//修改
export const staffSave = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return put('/api/staff/handle/' + id, p);
};
//详情
export const staffDetail = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return get('/api/staff/handle/' + id, p);
};

//删除
export const staffDel = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return del('/api/staff/handle' + id, p);
};
//列表
export const staffList = (p) => {
    p.headers = tools.getToken();
    return get('/api/staff/handle', p);
};

//获取可以操作绑定的店铺和权限
export const getStaffAuth = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return get('/api/staff/bind_source/' + id, p);
};
//添加和保存权限
export const saveStaffAuth = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return put('/api/staff/bind/' + id, p);
};

/**
 * 门店管理
 */
//新增
export const shopAdd = (p) => {
    p.headers = tools.getToken();
    return post('/api/shop/handle', p);
};
//修改
export const shopSave = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return put('/api/shop/handle/' + id, p);
};
//详情
export const shopDetail = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return get('/api/shop/handle/' + id, p);
};

//删除
export const shopDel = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return del('/api/shop/handle' + id, p);
};
//列表
export const shopList = (p) => {
    p.headers = tools.getToken();
    return get('/api/shop/handle', p);
};

//获取可以操作绑定的店铺和权限
export const getshopAuth = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return get('/api/shop/bind_source/' + id, p);
};
//添加和保存权限
export const saveshopAuth = (p) => {
    let id = p.id;
    delete p.id;
    p.headers = tools.getToken();
    return put('/api/shop/bind/' + id, p);
};